<!-- 查看碳值的弹窗 -->

<template>
  <div class="ViewCarbonValue">
    <div class="ViewCarbonValue_container">
      <div class="ViewCarbonValue_container_title">
        <div class="ViewCarbonValue_container_title_left">
          <img src="../../../assets/images/home/carbonFootprint.png" alt="">

          <!-- 总数量 -->
          <div class="ViewCarbonValue_container_title_left_text">Total:</div>
          <div class="ViewCarbonValue_container_title_left_co">{{ thousands(props.quotation.totalco) }}kg</div>
          <span>CO<sub>2</sub></span>

          <!-- 单位数量 -->
          <div class="ViewCarbonValue_container_title_left_text">Unit:</div>
          <div class="ViewCarbonValue_container_title_left_co">{{ thousands(props.quotation.unitco) }}kg</div>
          <span>CO<sub>2</sub></span>
        </div>
        <img class="ViewCarbonValue_container_title_close" src="../../../assets/images/supplier/quotation/if-close.png" alt="" @click="CloseDialog('close')">
      </div>

      <div class="ViewCarbonValue_container_detail">
        <div class="ViewCarbonValue_container_detail_item" v-for="(item, index) in carbonFootprintData" :key="index">
          <div class="ViewCarbonValue_container_detail_item_left">
            <div class="ViewCarbonValue_container_detail_item_left_step">{{ item.single_node ? item.single_name : item.start_location }}</div>
            <img src="../../../assets/images/home/toRight.png" alt="" v-show="!item.single_node">
            <div class="ViewCarbonValue_container_detail_item_left_step">{{ item.end_location }}</div>
          </div>
          <div class="ViewCarbonValue_container_detail_item_line"></div>
          <div class="ViewCarbonValue_container_detail_item_right">
            <div class="ViewCarbonValue_container_detail_item_right_value">{{ item.carbon_value }}</div>
            <span>CO<sub>2</sub></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup name="ViewCarbonValue">
import { ref, reactive, inject, defineProps } from 'vue'
// console.log(quatationDetailId.value);

// 关闭弹窗
import { defineEmits } from 'vue'
import { ElMessageBox } from 'element-plus'
import { allowOrRefuseQuo } from '@/api/order'
import { getQuotationDetail } from '@/api/quotation'
import { thousands } from '@/utils/thousands'
const props = defineProps({
  // quotation: {
  //   type: Object,
  //   required: true,
  //   default: () => ({}),
  //   co: {
  //     type: Number,
  //     required: true
  //   }
  // }
  quotation: {}
})

// 碳值的数据
const carbonFootprintData = reactive([
  {
    node_id: 0,
    node_type: 'transport',
    start_location: 'Material',
    end_location: 'Factory',
    carbon_value: '102.03g',
    single_node: false,
    single_name: ''
  },
  {
    node_id: 1,
    node_type: 'produce',
    start_location: '',
    end_location: '',
    carbon_value: '189.62g',
    single_node: true,
    single_name: 'Production Process'
  },
  {
    node_id: 2,
    node_type: 'produce',
    start_location: 'Factory',
    end_location: 'Qingdao Port',
    carbon_value: '32.21g',
    single_node: false,
    single_name: ''
  },
  {
    node_id: 3,
    node_type: 'produce',
    start_location: 'Qingdao Port',
    end_location: 'Dublin Port',
    carbon_value: '168.55g',
    single_node: false,
    single_name: ''
  },
  {
    node_id: 4,
    node_type: 'produce',
    start_location: 'Dublin Port',
    end_location: 'Destination',
    carbon_value: '33.67g',
    single_node: false,
    single_name: ''
  }])

const quotationDetail = async () => {
  // Production Process
  const productionProcess = (props.quotation?.materialCo === undefined ? 0 : props.quotation?.materialCo) + (props.quotation?.sampleProductionCo === undefined ? 0 : props.quotation?.sampleProductionCo) + (props.quotation?.sampleCo === undefined ? 0 : props.quotation?.sampleCo) + (props.quotation?.energyCo === undefined ? 0 : props.quotation?.energyCo) + (props.quotation?.packagingCo === undefined ? 0 : props.quotation?.packagingCo)
  // console.log('Production Process：', productionProcess)
  carbonFootprintData[0].carbon_value = (thousands(props.quotation.materialTransCo) === undefined ? '0' : thousands(props.quotation.materialTransCo)) + 'kg'
  carbonFootprintData[1].carbon_value = (thousands(productionProcess) === undefined ? '0' : thousands(productionProcess)) + 'kg'
  carbonFootprintData[2].carbon_value = (thousands(props.quotation.factoryPortCo) === undefined ? '0' : thousands(props.quotation.factoryPortCo)) + 'kg'
  carbonFootprintData[2].end_location = props.quotation.port
  carbonFootprintData[3].start_location = props.quotation.port
  carbonFootprintData[3].carbon_value = (thousands(props.quotation.internationalTransCo) === undefined ? '0' : thousands(props.quotation.internationalTransCo)) + 'kg'
  carbonFootprintData[4].carbon_value = (thousands(props.quotation.requireCo) === undefined ? '0' : thousands(props.quotation.requireCo)) + 'kg'
}

// 接收父组件传递的数据
const quatationDetailId = inject('quatationDetailId')
const emit = defineEmits(['customEvent'])
const CloseDialog = (msg2) => {
  console.log(msg2)
  emit('customEvent', msg2)
}

quotationDetail()
</script>

<style lang="scss" scoped>
.ViewCarbonValue {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.6) 100%);
  line-height: 1;
  text-align: left;
  overflow-y: scroll;

  &_container {
    width: 890px;
    margin: 80px auto 0;
    background-color: #fff;
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 300px;

    &_title {
      display: flex;
      justify-content: space-between;
      padding: 30px;
      background-color: rgba(248, 248, 248, 1);

      &_left {
        display: flex;
        align-items: center;

        &_text {
          font-size: 16px;
          margin-right: 5px;
        }

        img {
          width: 20px;
          height: 20px;
          margin-right: 10px;
        }

        &_co {
          font-size: 20px;
          font-weight: bold;
          color: rgba(39, 174, 96, 1);
        }

        span {
          font-size: 14px;
          color: rgba(39, 174, 96, 0.8);
          margin-left: 5px;
          margin-right: 30px;
        }
      }

      &_close {
        width: 20px;
        height: 20px;
        cursor: pointer;
      }
    }

    &_detail {
      width: 580px;
      margin: 50px auto 80px;

      &_item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 50px;
        position: relative;

        &_left {
          display: flex;
          align-items: center;
          margin-right: 30px;
          flex-shrink: 0;

          &_step {
            color: rgba(24, 24, 24, 1);
            font-size: 16px;
            line-height: 16px;
          }

          img {
            width: 16px;
            height: 16px;
            margin: 0 15px;
          }
        }

        &_line {
          width: 100%;
          height: 2px;
          background-image: linear-gradient(to right,
              #ccc 0%,
              #ccc 50%,
              transparent 50%);
          background-size: 10px 1px;
          background-repeat: repeat-x;
        }

        &_right {
          font-size: 16px;
          color: rgba(39, 174, 96, 1);
          font-weight: bold;
          display: flex;
          align-items: center;
          margin-left: 30px;

          span {
            color: rgba(36, 89, 2, 0.5);
            font-size: 12px;
            font-weight: 300;
            margin-left: 5px;
          }
        }
      }
    }
  }
}
</style>
